import React from "react";
import Section1 from "../../components/AboutUs/Section1";
import Section2 from "../../components/AboutUs/Section2";
// import TeamRAT from "../../components/AboutUs/TeamRAT";
import Section4 from "../../components/AboutUs/Section4";
import Section5 from "../../components/AboutUs/Section5";

export const AboutUs = () => {
  return (
    <div className="relative w-full overflow-hidden">
      <Section1 />
      <Section2 />
      {/* <TeamRAT /> */}
      {/*for commit  */}
      <Section4 />
      <Section5 />
    </div>
  );
};

export default AboutUs;
