import { FaAngleRight } from "react-icons/fa6";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import Thumbnail from "../Thumbnail/Page";
import { useEffect, useState } from "react";
import Aos from "aos";
 

const CatList = ({data,customCss}) => {
  const [catData] = useState([
    {
      id: 1,
     thumbnail: "/category/thcard.png",
      title: "Karma Calling",
      description:
        "Nothing matters when the world is at your feet.",
      season: "1 Season",
      year: "2024",
      rating: "U/A 16+",
      videoSrc:
        "https://media-content.akamaized.net/video/d1be0691bf91b4df93f492c4549d63ac/preview/1/16x9/320x180/h264_baseline.mp4",
      videoId: 333499420,
    },
    {
      id: 2,
     thumbnail: "/category/thcard.png",
      title: "The Witcher: Blood Origin",
      description:
        "Explore the origins of the Witcher universe .",
      season: "1 Season",
      year: "2024",
      rating: "U/A 16+",
      videoSrc:
        "https://media-content.akamaized.net/video/d1be0691bf91b4df93f492c4549d63ac/preview/1/16x9/320x180/h264_baseline.mp4",
      videoId: 293940724,
    },
    {
      id: 3,
      thumbnail: "/images/4.png",
      title: "Spider-Man: A New Beginning",
      description: "A thrilling reboot of the Spider-Man franchise.",
      season: "1 Season",
      year: "2024",
      rating: "U/A 16+",
      videoSrc:
        "https://media-content.akamaized.net/video/d1be0691bf91b4df93f492c4549d63ac/preview/1/16x9/320x180/h264_baseline.mp4",
      videoId: 291681025,
    },
    {
      id: 4,
      thumbnail: "/images/11.png",
      title: "The Amazing Journey",
      description:
        "Experience an epic adventure filled with twists .",
      season: "1 Season",
      year: "2024",
      rating: "U/A 16+",
      videoSrc:
        "https://media-content.akamaized.net/video/d1be0691bf91b4df93f492c4549d63ac/preview/1/16x9/320x180/h264_baseline.mp4",
      videoId: 291681026,
    },
    {
      id: 5,
      thumbnail: "/images/6.png",
      title: "The Lost Treasure",
      description:
        "Follow the quest to find the lost treasure of ancient.",
      season: "1 Season",
      year: "2024",
      rating: "U/A 16+",
      videoSrc:
        "https://media-content.akamaized.net/video/d1be0691bf91b4df93f492c4549d63ac/preview/1/16x9/320x180/h264_baseline.mp4",
      videoId: 290693753,
    },
    {
      id: 6,
      thumbnail: "/images/7.png",
      title: "The Dark Secrets",
      description:
        "Unravel the mysteries that lie hidden in the shadows.",
      season: "1 Season",
      year: "2024",
      rating: "U/A 16+",
      videoSrc:
        "https://media-content.akamaized.net/video/d1be0691bf91b4df93f492c4549d63ac/preview/1/16x9/320x180/h264_baseline.mp4",
      videoId: 293940725,
    },
    {
      id: 7,
      thumbnail: "/images/8.png",
      title: "The Final Frontier",
      description:
        "Journey through space and time in this thrilling adventure.",
      season: "1 Season",
      year: "2024",
      rating: "U/A 16+",
      videoSrc:
        "https://media-content.akamaized.net/video/d1be0691bf91b4df93f492c4549d63ac/preview/1/16x9/320x180/h264_baseline.mp4",
      videoId: 276703568,
    },
  ]);
     
  useEffect(() => {
    Aos.init({ 
      easing: 'ease-in', 
      duration: 1500,
      once: false,
     
    });
  }, []);

  
  

  return (
    <div className={`catList md:pl-[6.77vw] md:pt-[6.7vh] md:pb-[12.06vh]  !overflow-hidden ${customCss} `}
    data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-offset="150"
    >
  
      {data?.length !== 0 && (
        <>
          <Swiper
            spaceBetween={10}
            slidesPerView={5} 
            slidesPerGroup={1}
            navigation={true}
            loop={true}
            modules={[Navigation]}
            className="catSlider overflow-hidden"
            breakpoints={{
              // When the window width is >= 320px
              320: {
                slidesPerView: 1,
                slidesPerGroup: 1,
              },
              // When the window width is >= 480px
              480: {
                slidesPerView: 2,
                slidesPerGroup: 2,
              },
              // When the window width is >= 768px
              768: {
                slidesPerView: 4,
                slidesPerGroup: 4,
              },
              // When the window width is >= 1024px
              1024: {
                slidesPerView: 5,
                slidesPerGroup: 1,
              },
              // When the window width is >= 1440px
              1440: {
                slidesPerView: 5, // Adjust as per your requirement
                slidesPerGroup: 1,
              },
            }}
          >
            {catData.map((item, index) => (
              <SwiperSlide key={index}> 
              <div className="">
                <Thumbnail key={item.id} item={item} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      )} 
     
    </div>
  );
};

export default CatList;
