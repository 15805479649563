/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import img1 from "../../assets/AboutUs/1.png";
import img2 from "../../assets/AboutUs/2.png";
import img3 from "../../assets/AboutUs/3.png";
import img4 from "../../assets/AboutUs/4.png";

const images = [
  {
    src: img1,
    text: "2022 Dadasaheb Phalke Film Festival Award",
  },
  {
    src: img2,
    text: "2022 International Filmfare Award",
  },
  {
    src: img3,
    text: "2017 International Film Festival Award",
  },
  {
    src: img4,
    text: "2022 Lords Of Trends Award",
  },
];

export function Award() {
  return (
    <div className="md:pr-[4.58vw] grid grid-cols-1 md:grid-cols-4 gap-[16px]">
      {images.map((image, index) => (
        <div
          key={index}
          className="flex flex-col justify-center items-center pb-[4.44vh]"
        >
          <div className="w-[91vw] md:w-[20.68vw] flex bg-cover bg-center overflow-hidden relative">
            <img
              src={image.src}
              alt={`Image ${index + 1}`}
              className="w-full h-full object-fill filter grayscale hover:grayscale-0 transition duration-300 ease-in-out"
            />
          </div>
          <div className="flex flex-col items-center mt-[0.83vw]">
            <p
              className="font-montserrat text-[1.25vw] md:text-[0.93vw] font-light text-white"
              style={{ lineHeight: "1.5" }}
            >
              Nominated
            </p>
            <p
              className="font-montserrat text-[1.25vw] md:text-[0.93vw] font-light text-white"
              style={{ lineHeight: "1.5" }}
            >
              {image.text}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Award;
