import React, { useEffect } from "react";
import bgimg from "../../assets/AboutUs/film_texture___grain_explosion_by_stephanepellennec_d37pwfa-fullview.png";
import img_1 from "../../assets/Partners/1.png";
import img_2 from "../../assets/Partners/2.png";
import img_3 from "../../assets/Partners/3.png";
import img_4 from "../../assets/Partners/4.png";
import img_5 from "../../assets/Partners/5.png";
import img_6 from "../../assets/Partners/6.png";
import img_7 from "../../assets/Partners/7.png";
import img_8 from "../../assets/Partners/8.png";
import img_9 from "../../assets/Partners/9.png";
import img_10 from "../../assets/Partners/10.png";
import img_11 from "../../assets/Partners/11.png";
import img_12 from "../../assets/Partners/12.png";
import img_13 from "../../assets/Partners/13.png";
import img_14 from "../../assets/Partners/14.png";
import img_15 from "../../assets/Partners/15.png";
import img_16 from "../../assets/Partners/16.png";
import img_17 from "../../assets/Partners/17.png";
import img_18 from "../../assets/Partners/18.png";
import img_19 from "../../assets/Partners/19.png";
import img_20 from "../../assets/Partners/20.png";
import img_21 from "../../assets/Partners/21.png";
import img_22 from "../../assets/Partners/22.png";
import img_23 from "../../assets/Partners/23.png";
import img_24 from "../../assets/Partners/24.png";
import img_25 from "../../assets/Partners/25.png";
import "./MultipleRows.scss";

const images = [
  img_1, img_2, img_3, img_4, img_5, img_6, img_7, img_8, img_9, img_10,
  img_11, img_12, img_13, img_14, img_15, img_16, img_17, img_18, img_19, img_20,
  img_21, img_22, img_23, img_24, img_25,
];

function StaticGrid() {
  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll(".parallax-bg");
      elements.forEach((element) => {
        const speed = element.getAttribute("data-speed");
        const offset = window.pageYOffset * speed;
        element.style.backgroundPosition = `center ${offset}px`;
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
   
          <div className="px-4 md:px-0 py-8">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-6 md:gap-8">
              {images.map((image, index) => (
                <div key={index} className="image-container md:!ml-0">
                <div className="outer-scratch parallax-bg" data-speed="0.5">
                  <div className="inner-scratch">
                    <div className="background grain "  >
                    <div
            key={index}
            className="relative flex justify-center items-center cursor-pointer overflow-hidden"
          >
            <div
              className="w-[44vw] md:w-[28vw] h-[18vh] md:h-[26vh] flex justify-center items-center parallax-bg bg-cover bg-center"
              
             
            >
              <img
                src={image}
                alt={`Partner ${index}`}
                className="md:!w-[10vw] !w-[25vw] md:!h-[8vw] !h-[24vw] object-contain filter transition-transform duration-300 hover:scale-105"
              />
            </div>
          </div>
                  
                    </div>
                  </div>
                </div>
                  
                </div>
              ))}
            </div>
          </div>
       
  );
}

export default StaticGrid;
